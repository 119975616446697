import React from 'react';

import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

const SectionPricing = ({ pricingOptions, intl }) => {
  return (
    <div className={css.pricingContainer}>
      {pricingOptions?.map(option => {
        const priceToMoney = new Money(option.fee, option.currency);
        const formattedPrice = formatMoney(intl, priceToMoney);
        return (
          <div key={option.type} className={css.card}>
            <h2>{option.type.toUpperCase()}</h2>
            <p>{formattedPrice}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SectionPricing;
